import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/Generic.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Terms & conditions`}</h1>
    <p><strong parentName="p">{`This site is owned and operated by Perfect Pergolas Ltd. Registered Office: c/o McGills Chartered Accountants, Oakley House, Cirencester, Glos GL7 1US. Company Registration No: 7246705. To contact us, please email: `}<a parentName="strong" {...{
          "href": "mailto:info@perfectpergolas.co.uk"
        }}>{`info@perfectpergolas.co.uk`}</a>{` and we will do our best to deal with any enquiry you may have connected to the business.`}</strong></p>
    <p>{`Please read the Terms & Conditions below before accessing our website. By using our website you agree to be legally bound by the T&C’s as outlined below. If you do not wish to be bound by these terms and conditions please leave our website and do not purchase any goods.`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Prices are listed in £GBP and are inclusive of VAT unless otherwise stated. Payment is usually by credit or debit card and is taken when the order is placed which protects us from card fraud. You must be over 18 years old to purchase products, using the payment methods displayed on our website. If you do not qualify you may not use our website.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`We reserve the right to change the advertised prices at any time. You will pay the price in force at the time that you place your order. The price on the order form will be confirmed by us as part of the acceptance procedure and we will endeavor to ensure that the prices displayed on our website are correct at all times. If a pricing problem arises and the actual product cost price is higher than that stated on your order, we will contact you and you may choose to either proceed with or cancel the order.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Every care has been taken with regard to the content of this website and to ensure that the prices quoted are correct at the time of publishing and that all products have been fairly described. In the examples the dimensions are approximate only and we have taken every measure to display as accurately as possible the colours and textural variations of the wood on the website. We disclaim all warranties, express or implied, as to the accuracy of the information contained in any use of the materials shown and we shall not be liable to any person for any loss or damage incurred from using any of the information contained in any of the materials or descriptions that appears on the website. We reserve the right to change any content of the website without notice.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`All items are subject to availability and we will inform you as soon as possible if the goods you have ordered are not available.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`The cost of delivery for our Pergola Modules is free. However, if you cancel your order after the goods have been dispatched you will be responsible for paying any costs incurred by us, our suppliers or our affiliated members in delivering and collecting the order from you. Once an order has been placed, we will contact you to arrange a delivery date. Please note it is not possible to contact the delivery driver once he has left. A signature is required on delivery otherwise a re-delivery charge will be incurred.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`We aim to deliver within 21 days from receipt of order by a designated courier or our own transport. Accessories are held in stock by our distributors and/or affiliated suppliers who arrange delivery on our behalf. If you have any questions or problems with regard to the delivery of these accessories, please contact us with your name and order number and we will endeavor to help in any way possible. If you have any questions or problems with the pergola system, please use the Contact Us form on our website and we will try and answer your question within 48hours. The pergola system is custom made per order and, as such, delivery times are dependent upon the workload at the saw mill. If a delay has occurred we will contact you to inform you as to how long delivery will be. In the unlikely event of a delay, please allow 28 days for delivery. For more complex systems a delivery date of 6 weeks or more may be likely in some cases. If lead-time is critical, please do not hesitate to contact us prior to placing an order to discuss your requirements. This is for guidance only and subject to change without notice or recompense. We are not liable for any costs incurred by you due to delayed delivery as this is outside our control and because of the size and shape of the product, delivery may be to curbside only at the drivers discretion.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Deliveries to Northern Ireland, Isle of Man, Isle of Wight, Channel Islands, Silly Isles, Northern Scotland and the Scottish Isles may be arranged subject to additional delivery charges. Any additional charges will be notified to you prior to your order being accepted and acknowledged.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`If, once a delivery has been made, you find a part missing or damaged we will endeavor to rectify the situation as soon as possible. However, with some parts, we may not be able to do this at once as some items will have to be re-made to your requirements but we will do everything possible to resolve the issue and get the damaged or missing part to you as quickly as possible.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`In the unlikely event that you receive damaged goods you must sign the delivery note accordingly and report in writing to the carrier at once, with a copy to us, within 48 hours of receipt. We will then organize a replacement.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Returns Policy: Accessories;`}</strong>{` If after you have received your goods you find the item(s) unsuitable or that you have ordered incorrectly you must inform, in writing via email or post within 7 days of delivery, the respective affiliated supplier (name and address found on accessories web page) and may, at your own cost, return the good(s) for a refund or an exchange as long as it is unused and returned in the original packaging. Goods are not sold on a trial basis. Administration, re-stocking, inclusive delivery and carriage costs may apply. Products must be returned with original Proof of Purchase and complete with all components. Your Statutory Rights are not affected. In addition, goods not returned in a satisfactory, re-salable condition may, at the suppliers/manufacturers discretion, be subject to further costs, payable by you. Damage caused by yourself due to failure to read the instructions or inappropriate opening of cartons are not covered by the manufacturers guarantee. Unauthorised returns may be refused. See `}<a parentName="p" {...{
            "href": "http://www.financialombudsman.org"
          }}>{`www.financialombudsman.org`}</a>{` for further details`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Returns Policy: Pergolas;`}</strong>{`
Due to the bespoke nature of the product, refunds can only be given according to your legal rights and does not apply to the custom finished wooden parts of the system. You have up to 7 working days after delivery to inform us you require a refund. This must be done in writing via email or post within 7 working days of delivery. Please contact our Customer Service Department at `}<a parentName="p" {...{
            "href": "mailto:info@perfectpergolas.co.uk"
          }}>{`info@perfectpergolas.co.uk`}</a>{` who will inform you of collection options, costs and explain our refund process. You will be expected to keep the item in a resalable condition. If we collect the item from you we will charge you the cost of collection and any repackaging or re-stocking costs. Unauthorised returns will be refused.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Having placed an on-line order with us, you will automatically receive an email confirmation receipt of your order to the email address you entered on the on-line order form. Once you have received this confirmation the contract for our sale and your purchase of the goods shall be made and the contract shall be formed at the place from which our acceptance email is sent. No contract will be informed until you receive confirmation from us that we have accepted your order. If you do not receive a Confirmation email, please contact us to ensure your order has been accepted.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Orders received on Saturdays, Sundays or public holidays and orders received after 2pm on weekdays, will be processed the next working day.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Please ensure that all details that you provide for the purpose of purchasing products offered on our website are correct, that the credit or debit card used is your own and has sufficient funds or credit facilities to cover the cost of your purchase. We reserve the right to obtain validation and verification of the authenticity of your credit or debit card details before providing you with any product or service.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`You have the right to cancel your order within 7 working days of receipt of the goods with the exception of the wooden made-to-measure items that construe the ‘pergola’. See your normal Statutory Rights for further information. Where the goods are delivered to a third part (ie: as a gift) you will only be able to exercise this right if you are able to return to us the goods you ordered.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`The links to other websites, that we have placed on our own website, are sites that we think may be of interest. They have not been vetted by us as we do not have any control over their content and, as such, we cannot accept any liability with regard to the use of these websites.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Any disclaimers and exclusions of liability in these T&C’s shall not apply to any damages arising from death or personal injury caused by the negligence of ourselves or any of our employees, agents or fraud. These disclaimers and exclusions shall be governed and construed in accordance with English Law. If any provisions of these disclaimers and exclusions shall be unlawful, void or for any reason unenforceable then that provision shall be deemed severable and shall not affect the validity and enforceability of the remaining provisions. If we are liable to you for any reason, our liability will be limited to the amount paid by you for the product concerned.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`We reserve the right to change these T&C’s as and when required. Please check these terms and conditions regularly. Unless explicitly stated to the contrary, any new features including content shall be subject to these Terms and Conditions.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`All rights including copyright, logos and domain names in our website are owned by Perfect Pergolas Ltd, its affiliates or other third party licensors and any use of this website or our company name and or logo’s, the website content, including copying or storing it or them in whole or part, other than for your own personal, non-commercial use, is strictly prohibited without our written consent. Do not modify, distribute or re-post anything on our website for any purpose. All product and company names and logos mentioned in our website are the trademarks, service marks or trading names of their respective owners, including us.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`The goods and services specified on our website are directed solely at those customers living in the United Kingdom mainland.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`We make every effort to ensure that our website is free from viruses or defects. However, we cannot guarantee that your use of this website, or any websites accessible through it, will not cause damage to your computer. It is your responsibility to ensure that the right equipment is available to use the website and protect yourself from anything that may damage it. We shall not be liable to any person for any loss or damage which may arise to computer equipment as a result of using this website.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`This website and any content contained within and any contract bought into being as a result of usage of this website are governed by and construed in accordance with English Law. The parties to any such contract agree to submit to the exclusive jurisdiction of the courts of England and Wales. All contracts are concluded in English.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`In accordance with the Data Protection Act 1998 we will never make your personal data available to any company for marketing purposes. However, our affiliated suppliers may hold your records so that your details and/or order can be processed. We use secure server technology with which to process your order. Cookies are small pieces of information which are stored by your browser on your computer’s hard drive, whilst it is not currently our company policy to utilize cookies we maintain the right to use them without prior notification if we deem it necessary and they will never store any of your personal details relating to yourself or your credit cards.`}</p>
      </li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      